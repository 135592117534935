/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { userLogin } from "../store/user/actions";
import { Button, TextField } from "ui-kit";

const LoginPage = () => {
  const dispatch = useDispatch();

  const onSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => (data[key] = value));

    dispatch(userLogin(data));
  };

  return (
    <div
      className="pt-16 h-full"
      style={{ background: "rgba(245, 248, 251, 1)" }}
    >
      <form
        className="mx-auto max-w-lg shadow-2xl rounded-lg p-8 bg-white"
        onSubmit={onSubmit}
      >
        <h1 className="text-center text-lg font-bold mb-6">Login</h1>

        <div className="flex flex-col mb-8 gap-4">
          <TextField
            isFull
            inputProps={{
              type: "email",
              id: "email",
              placeholder: "Email",
              name: "email",
              pattern: "[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
              required: true,
            }}
            labelProps={{ htmlFor: "email" }}
          />
          <TextField
            isFull
            inputProps={{
              type: "password",
              id: "password",
              placeholder: "Senha",
              name: "password",
              minLength: "6",
              required: true,
            }}
            labelProps={{ htmlFor: "password" }}
          />

          <a className="text-primary text-xs -mt-2" href="#">
            Esqueceu a senha?
          </a>
        </div>

        <div className="flex gap-4 justify-center">
          <Button to="/criar-conta" variant="secondary" component={Link}>
            Criar
          </Button>

          <Button type="submit">Login</Button>
        </div>
      </form>
    </div>
  );
};

export default LoginPage;
