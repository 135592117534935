import { components } from "react-select";
import React from "react";
import PropTypes from "prop-types";
import Creatable from "react-select/creatable";

const CustomOption = (props) => {
  return props.data.__isNew__ ? (
    <components.Option {...props}>
      Criar entidade <strong>{props.data.value}</strong>
    </components.Option>
  ) : (
    <components.Option {...props} children={props.children} />
  );
};

function EntitySelect(props) {
  return (
    <Creatable
      isClearable
      isDisabled={props.disabled}
      options={props.options}
      components={{ Option: CustomOption }}
      placeholder={!props.options?.length ? "Carregando..." : "Selecione"}
      value={props.option}
      onChange={(option) =>
        props.onChange(
          option ? { ...option, isNew: !!option?.__isNew__ } : null
        )
      }
      inputId={props.id}
      name={props.id}
    />
  );
}

EntitySelect.propTypes = {
  id: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  option: PropTypes.object,
  onChange: PropTypes.func.isRequired,
};

export default EntitySelect;
