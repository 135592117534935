//const LOCAL = "local";
const DEVELOPMENT = "development";
const PRODUCTION = "production";
const BACKEND_DEV_URL = 'https://stage.cartaconvite.com.br/api';
const BACKEND_LOCAL_URL = 'http://localhost:8787/api';
const BACKEND_PROD_URL = 'https://backend.cartaconvite.com.br/api';

/*
    determines and returns the appropriate backend URL
*/
export const getBackendUrl = (environment = DEVELOPMENT) => {
    if (environment === PRODUCTION) return BACKEND_PROD_URL;
    if (environment === DEVELOPMENT) return BACKEND_LOCAL_URL; // for local development
    //if (environment === DEVELOPMENT) return BACKEND_DEV_URL;

    // catch all:
    return BACKEND_DEV_URL;
}