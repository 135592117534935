import React, { useState } from "react";
import PropTypes from "prop-types";
import Select from "react-select";

const typeOptions = [
  { value: "GOVERNO", label: "Governo" },
  { value: "PRIVADA", label: "Privada" },
];

const EntityForm = (props) => {
  const [newEntity, setNewEntity] = useState({
    tipo: "",
    subtipo: "",
    cidade: "",
    cep: "",
  });

  const setField = (field, value) =>
    setNewEntity((newEntity) => ({ ...newEntity, [field]: value }));

  const onSubmit = (event) => {
    event.preventDefault();
    props.createEntity(newEntity);
  };

  return (
    <form onSubmit={onSubmit}>
      <div className="row">
        <div className="col-12 col-sm-6 mb-3">
          <label htmlFor="tipo_da_entidade" className="form-label">
            Tipo
          </label>
          <Select
            options={typeOptions}
            inputId={"tipo_da_entidade"}
            name="tipo_da_entidade"
            isSearchable={false}
            value={typeOptions.find((o) => o.value === newEntity.tipo)}
            onChange={(option) => setField("tipo", option.value)}
          />
        </div>
        <div className="col-12 col-sm-6 mb-3">
          <label htmlFor="subtipo_da_entidade" className="form-label">
            Subtipo
          </label>
          <input
            type="text"
            className="form-control"
            id="subtipo_da_entidade"
            name="subtipo_da_entidade"
            required
            value={newEntity.subtipo}
            onChange={(event) => setField("subtipo", event.target.value)}
          />
        </div>
      </div>

      <div className="row">
        <div className="col-12 col-sm-3 mb-3">
          <label htmlFor="cep_da_entidade" className="form-label">
            Cep
          </label>
          <input
            type="text"
            className="form-control"
            id="cep_da_entidade"
            name="cep_da_entidade"
            required
            value={newEntity.cep}
            onChange={(event) => setField("cep", event.target.value)}
          />
        </div>
        <div className="col-12 col-sm-9 mb-3">
          <label htmlFor="cidade_da_entidade" className="form-label">
            Cidade
          </label>
          <input
            type="text"
            className="form-control"
            id="cidade_da_entidade"
            name="cidade_da_entidade"
            required
            value={newEntity.cidade}
            onChange={(event) => setField("cidade", event.target.value)}
          />
        </div>
      </div>

      <button className="btn btn-primary btn-lg w-100 mt-5">
        Criar Entidade
      </button>
    </form>
  );
};

EntityForm.propTypes = {
  createEntity: PropTypes.func.isRequired,
};

export default EntityForm;
