export const USER_LOGIN_START = "USER_LOGIN_START";
export const userLoginStart = () => ({
  type: USER_LOGIN_START,
});

export const USER_LOGIN_SUCCESS = "USER_LOGIN_SUCCESS";
export const userLoginSuccess = (login) => ({
  type: USER_LOGIN_SUCCESS,
  login,
});

export const USER_LOGIN_ERROR = "USER_LOGIN_ERROR";
export const userLoginError = (error) => ({
  type: USER_LOGIN_ERROR,
  error,
});

export const userLogin = (email, password) => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(userLoginStart());

    try {
      const login = await cartaConvite.login(email, password);
      dispatch(userLoginSuccess(login));
    } catch (error) {
      dispatch(userLoginError(error));
    }
  };
};

export const USER_LOGOUT = "USER_LOGOUT";
export const userLogout = () => ({
  type: USER_LOGOUT,
});
