import { Link, useLocation } from "react-router-dom";
import queryString from 'query-string'
import { useDispatch } from "react-redux";
import { userLogout } from "../store/user/actions";
import { Button, Appbar as BaseAppbar } from "ui-kit";

const Appbar = () => {
  const dispatch = useDispatch();
  const { edictId } = queryString.parse(useLocation().search)

  return (
    <BaseAppbar logo="/img/logo.png">
      <div className="flex gap-2">
        <Button
          variant="destructive"
          to="/criar-edital"
          onClick={() => dispatch(userLogout())}
        >
          Logout
        </Button>

        <Button variant="primary" to={edictId ? `/editar-edital/${edictId}` : "/criar-edital"} component={Link}>
          {edictId ?
              "Editar"
            :
              "Adicionar Edital"
          }
        </Button>
      </div>
    </BaseAppbar>
  );
};

export default Appbar;
