import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

const Dropzone = ({ onChange }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      onChange && onChange(acceptedFiles);
    },
    [onChange]
  );
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } =
    useDropzone({ onDrop, multiple: false });

  return (
    <>
      <ul>
        {acceptedFiles.map((file) => (
          <li key={file.name} className="font-semibold text-gray-700">
            {file.name} - {(file.size / (1024 * 1024)).toFixed(2)}MB
          </li>
        ))}
      </ul>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {isDragActive ? (
          <span>Adicione seu arquivo aqui</span>
        ) : (
          <div className="border-2 rounded-2xl p-4 text-center border-dashed bg-gray-50 hover:bg-gray-100 cursor-pointer transition-colors">
            <span>Adicione seu arquivo aqui</span>
          </div>
        )}
      </div>
    </>
  );
};

export default Dropzone;
