
/**
 * used to map static values for modalidade to labels
 * 
   { value: "CONCORRENCIA", label: "Concorrência" },
   { value: "TOMADA_DE_PRECO", label: "Tomada de Preço" },
   { value: "CONVITE", label: "Convite" },
   { value: "RDC", label: "RDC - Registro Diferenciado de Contratação" },
   { value: "PREGAO", label: "Pregão" },
   { value: "OUTRO", label: "Outro" },
 * 
 */
 export const getModalityLabel = (modalityValue) => {
    if (modalityValue === 'CONCORRENCIA') return 'Concorrência';
    if (modalityValue === 'TOMADA_DE_PRECO') return 'Tomada de Preço';
    if (modalityValue === 'CONVITE') return 'Convite';
    if (modalityValue === 'RDC') return 'RDC - Registro Diferenciado de Contratação';
    if (modalityValue === 'PREGAO') return 'Pregão';
    if (modalityValue === 'PREGAO_ELETRONICO') return 'Pregão Eletronico';
    if (modalityValue === 'PREGAO_PRESENCIAL') return 'Pregão Presencial';
    if (modalityValue === 'OUTRO') return 'Outro';

    return '';
 } 