import {
  CREATE_EDICT_ERROR,
  CREATE_EDICT_RESET,
  CREATE_EDICT_START,
  CREATE_EDICT_SUCCESS,
  CREATE_ENTITY_ERROR,
  CREATE_ENTITY_START,
  CREATE_ENTITY_SUCCESS,
  FETCH_ENTITIES_ERROR,
  FETCH_ENTITIES_START,
  FETCH_ENTITIES_SUCCESS,
  SET_ENTITY_OPTION,
} from "./actions";

const initialState = {
  entities: {
    loading: false,
    error: null,
    data: null,
  },
  entity: {
    loading: false,
    error: null,
    option: null,
  },
  edict: {
    loading: false,
    error: null,
    data: null,
  },
};

export default function createEdictReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_ENTITIES_START: {
      return {
        ...state,
        entities: { ...state.entities, loading: true, error: null, data: null },
      };
    }
    case FETCH_ENTITIES_ERROR: {
      return {
        ...state,
        entities: { ...state.entities, loading: false, error: action.error },
      };
    }
    case FETCH_ENTITIES_SUCCESS: {
      return {
        ...state,
        entities: { ...state.entities, loading: false, data: action.entities },
      };
    }

    case SET_ENTITY_OPTION: {
      return {
        ...state,
        entity: { ...state.entity, option: action.option },
      };
    }

    case CREATE_ENTITY_START: {
      return {
        ...state,
        entity: { ...state.entity, loading: true, error: null },
      };
    }
    case CREATE_ENTITY_ERROR: {
      return {
        ...state,
        entity: { ...state.entity, loading: false, error: action.error },
      };
    }
    case CREATE_ENTITY_SUCCESS: {
      return {
        ...state,
        entities: {
          ...state.entities,
          data: [action.entity, ...state.entities.data],
        },
        entity: { ...state.entity, loading: false },
      };
    }

    case CREATE_EDICT_START: {
      return {
        ...state,
        edict: { ...state.edict, loading: true, error: null },
      };
    }
    case CREATE_EDICT_SUCCESS: {
      return {
        ...state,
        edict: { ...state.edict, loading: false, data: action.edict },
      };
    }
    case CREATE_EDICT_ERROR: {
      return {
        ...state,
        edict: { ...state.edict, loading: false, error: action.error },
      };
    }

    case CREATE_EDICT_RESET: {
      return {
        ...initialState,
        entities: state.entities,
      };
    }

    default:
      return state;
  }
}
