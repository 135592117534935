import {
  FETCH_EDICT_START,
  FETCH_EDICT_SUCCESS,
  FETCH_EDICT_ERROR,
  FETCH_EDICTS_START,
  FETCH_EDICTS_SUCCESS,
  FETCH_EDICTS_ERROR,
  UPDATE_EDICT_START,
  UPDATE_EDICT_SUCCESS,
  UPDATE_EDICT_ERROR,
  UPDATE_EDICT_RESET,
  EDICT_UPLOAD_START,
  EDICT_UPLOAD_SUCCESS,
  EDICT_UPLOAD_ERROR,
  SET_EDICT_ENTITY,
} from "./actions";

const initialState = {
  edicts: {
    loading: false,
    error: null,
    data: null,
  },
  current: {
    loading: false,
    error: null,
    data: null,
  },
  updating: {
    id: null,
    loading: false,
    error: null,
  },
};

export default function edictReducer(state = initialState, action) {
  switch (action.type) {
    case FETCH_EDICT_START: {
      return {
        ...state,
        current: { ...state.current, loading: true, error: null },
      };
    }
    case FETCH_EDICT_SUCCESS: {
      const edictData = [
        ...(state.edicts.data ?? []).filter(
          (edict) => edict._id !== action.edict._id
        ),
        action.edict,
      ];

      return {
        ...state,
        edicts: { ...state.edicts, data: edictData },
        current: {
          ...state.current,
          loading: false,
          data: action.edict,
        },
      };
    }
    case FETCH_EDICT_ERROR: {
      return {
        ...state,
        current: {
          loading: false,
          error: action.error,
          data: null,
        },
      };
    }

    case SET_EDICT_ENTITY: {
      return {
        ...state,
        current: {
          ...state.current,
          data: { ...state.current.data, entity: action.entity },
        },
      };
    }

    case FETCH_EDICTS_START: {
      return {
        ...state,
        edicts: { ...state.edicts, loading: true, error: null },
      };
    }
    case FETCH_EDICTS_SUCCESS: {
      return {
        ...state,
        edicts: { ...state.edicts, loading: false, data: action.edicts },
      };
    }
    case FETCH_EDICTS_ERROR: {
      return {
        ...state,
        current: { ...state.current, loading: false, error: action.error },
      };
    }

    case EDICT_UPLOAD_START: {
      return {
        ...state,
        updating: {
          ...state.updating,
          loading: true,
          error: null,
        },
      };
    }
    case EDICT_UPLOAD_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          data: { ...state.current.data, ...action.data },
        },
        updating: {
          ...state.updating,
          loading: false,
          error: null,
        },
      };
    }
    case EDICT_UPLOAD_ERROR: {
      return {
        ...state,
        updating: {
          ...state.updating,
          loading: false,
          error: action.error,
        },
      };
    }
    case UPDATE_EDICT_START: {
      return {
        ...state,
        updating: {
          id: action.id,
          loading: true,
          error: null,
        },
      };
    }
    case UPDATE_EDICT_SUCCESS: {
      return {
        ...state,
        current: {
          ...state.current,
          data: { ...state.current.data, ...action.data },
        },
        updating: { ...state.updating, loading: false, error: null },
      };
    }
    case UPDATE_EDICT_ERROR: {
      return {
        ...state,
        updating: { id: null, loading: false, error: action.error },
      };
    }
    case UPDATE_EDICT_RESET: {
      return {
        ...state,
        updating: { ...initialState.updating },
      };
    }


    default:
      return state;
  }
}
