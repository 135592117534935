export const FETCH_ENTITIES_START = "FETCH_ENTITIES_START";
export const fetchEntitiesStart = () => ({
  type: FETCH_ENTITIES_START,
});

export const FETCH_ENTITIES_SUCCESS = "FETCH_ENTITIES_SUCCESS";
export const fetchEntitiesSuccess = (entities) => ({
  type: FETCH_ENTITIES_SUCCESS,
  entities,
});

export const FETCH_ENTITIES_ERROR = "FETCH_ENTITIES_ERROR";
export const fetchEntitiesError = (error) => ({
  type: FETCH_ENTITIES_ERROR,
  error,
});

export const fetchEntities = () => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(fetchEntitiesStart());

    try {
      const entities = await cartaConvite.getEntities();
      dispatch(fetchEntitiesSuccess(entities));
    } catch {
      dispatch(fetchEntitiesError());
    }
  };
};

export const SET_ENTITY_OPTION = "SET_ENTITY_OPTION";
export const setEntityField = (option) => ({
  type: SET_ENTITY_OPTION,
  option,
});

export const CREATE_ENTITY_START = "CREATE_ENTITY_START";
export const createEntityStart = () => ({
  type: CREATE_ENTITY_START,
});

export const CREATE_ENTITY_SUCCESS = "CREATE_ENTITY_SUCCESS";
export const createEntitySuccess = (entity) => ({
  type: CREATE_ENTITY_SUCCESS,
  entity,
});

export const CREATE_ENTITY_ERROR = "CREATE_ENTITY_ERROR";
export const createEntityError = (error) => ({
  type: CREATE_ENTITY_ERROR,
  error,
});

export const createEntity = (entityParam) => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(createEntityStart());

    try {
      const entity = await cartaConvite.createEntity(entityParam);
      dispatch(createEntitySuccess(entity));
      dispatch(setEntityField({ value: entity._id, label: entity.nomeDaEntidade, isNew: false }));
    } catch (error) {
      dispatch(createEntityError(error));
    }
  };
};

export const CREATE_EDICT_START = "CREATE_EDICT_START";
export const createEdictStart = () => ({
  type: CREATE_EDICT_START,
});

export const CREATE_EDICT_SUCCESS = "CREATE_EDICT_SUCCESS";
export const createEdictSuccess = (edict) => ({
  type: CREATE_EDICT_SUCCESS,
  edict,
});

export const CREATE_EDICT_ERROR = "CREATE_EDICT_ERROR";
export const createEdictError = (error) => ({
  type: CREATE_EDICT_ERROR,
  error,
});

export const CREATE_EDICT_RESET = "CREATE_EDICT_RESET";
export const createEdictReset = () => ({
  type: CREATE_EDICT_RESET,
});

export const createEdict = (newEdict) => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(createEdictStart());

    try {
      const edict = await cartaConvite.createEdict(newEdict);
      dispatch(createEdictSuccess(edict));
    } catch (error) {
      dispatch(createEdictError(error.message));
    }
  };
};
