import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";

import cartaConvite from "../api/carta-convite";

import createEdictReducer from "./createEdict/reducer";
import edictReducer from "./edict/reducer";
import userReducer from "./user/reducer";

export const reducers = combineReducers({
  createEdict: createEdictReducer,
  edict: edictReducer,
  user: userReducer,
});

const store = createStore(
  reducers,
  composeWithDevTools(
    applyMiddleware(thunk.withExtraArgument({ cartaConvite }))
  )
);

export default store;
