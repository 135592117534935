import { getBackendUrl } from '../config/backendUrl';

class CartaConvite {
  
  #url = getBackendUrl(process.env.NODE_ENV);

  getHeaders({ contentType }) {
    const headers = new Headers();

    if (contentType !== null) {
      headers.append("Content-Type", "application/json");
    }

    const authorization = localStorage.getItem("token");
    if (authorization) {
      headers.append("Authorization", authorization);
    }

    return headers;
  }

  async request({ endpoint, method = "GET", body, contentType, blob }) {
    const url = new URL(this.#url + endpoint);

    body =
      typeof body === "string" || body === undefined || body instanceof FormData
        ? body
        : JSON.stringify(body);

    const response = await fetch(url.href, {
      headers: this.getHeaders({ contentType }),
      method,
      body,
    });

    if (!response.ok) {
      const data = await response.json()
      throw new Error(data.error);
    }

    if(blob){
      const data = await response.blob();
      return data;
    }

    if(method !== "DELETE"){
      const data = await response.json();
      return data;
    }
  }

  async login({ email, password }) {
    const data = await this.request({
      endpoint: "/login",
      method: "POST",
      body: { email, password },
    });

    return data;
  }

  async getEdicts() {
    const data = await this.request({ endpoint: "/editais" });

    return data;
  }

  async getEdict(id) {
    const data = await this.request({ endpoint: `/editais/${id}` });

    return data;
  }

  async createEdict(edictData) {
    const data = await this.request({
      endpoint: `/editais`,
      method: "POST",
      body: edictData,
    });

    return data;
  }

  async updateEdict(edictData, id) {
    const data = await this.request({
      endpoint: `/editais/${id}`,
      method: "PUT",
      body: edictData,
    });

    return data;
  }

  async uploadEdictFiles(files, id) {
    const data = await this.request({
      endpoint: `/editais/${id}/upload`,
      method: "PATCH",
      body: files,
      contentType: null,
    });

    return data;
  }

  async deleteFile(edictId, fileId) {
    const data = await this.request({
      endpoint: `/editais/${edictId}/file/${fileId}`,
      method: "DELETE",
      contentType: null,
    });

    return data;
  }

  async getEntities() {
    const data = await this.request({ endpoint: `/entidades/` });

    return data;
  }

  async getEntity(id) {
    const data = await this.request({ endpoint: `/entidades/${id}` });

    return data;
  }

  async createEntity(entity) {
    const data = await this.request({
      endpoint: `/entidades/`,
      method: "POST",
      body: entity,
    });

    return data;
  }

  async getFile(editalId, filename) {
    const data = await this.request({
      endpoint: `/editais/${editalId}/download/${filename}`,
      blob: true
    })

    return data
  }
}

const cartaConvite = new CartaConvite();

export default cartaConvite;
