import { Redirect } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  createEdict,
  createEdictReset,
  createEntity,
  fetchEntities,
  setEntityField,
} from "../store/createEdict/actions";
import EntitySelect from "../components/EntitySelect";
import EntityForm from "../components/EntityForm";
import Select from "react-select";

const CreateEdictPage = () => {
  const { entities, entity, edict } = useSelector((state) => state.createEdict);
  const dispatch = useDispatch();
  const [cep, setCep] = useState("");

  useEffect(() => {
    dispatch(fetchEntities());
  }, [dispatch]);

  const onSubmit = (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const data = {};
    formData.forEach((value, key) => value && (data[key] = value));

    dispatch(
      createEdict({
        ...data,
        entidadeId: entity.option.value,
        nomeDaEntidade: entity.option.label,
      })
    );
  };

  useEffect(() => {
    return () => {
      dispatch(createEdictReset());
    };
  }, [dispatch]);

  if (edict.data) {
    return <Redirect to={`/edital?edictId=${edict.data._id}`} />;
  }

  const entitiesOptions = entities.data?.map(({ _id, nomeDaEntidade }) => ({
    value: _id,
    label: nomeDaEntidade,
  }));

  const onValorChange = (e) => {
      let v = e.target.value
      if(v){
        e.target.value = (v.indexOf(".") >= 0) ? (v.substr(0, v.indexOf(".")) + v.substr(v.indexOf("."), 3)) : v;
      }
  }

  const onEntityChange = (option) => {
    // if this is for a new entity, set setEntityField. 
    if (option?.__isNew__) {
      return dispatch(setEntityField(option));
    }

    if(option){
      const entityCep = entities.data.filter(entity => entity._id === option.value)[0].cep
      setCep(entityCep)
    }

    return dispatch(setEntityField(option));
  }

  return (
    <div className="bootstrap mt-5 mx-auto max-w-2xl">
      <div className="pb-5">
        <div className="row mb-5">
          <div className="col-12">
            <h2>Entidade</h2>
          </div>

          <div className="col-12 col-md-9 mb-3">
            <label htmlFor={"entitySelect"} className="form-label">
              Nome da entidade
            </label>

            <EntitySelect
              id={"entitySelect"}
              disabled={!entities.data}
              options={entitiesOptions}
              option={entity.option}
              onChange={(option) => onEntityChange(option)}
            />
          </div>

          {entity.option?.isNew && (
            <EntityForm
              createEntity={(data) => {
                dispatch(
                  createEntity({ ...data, nomeDaEntidade: entity.option.label })
                );
              }}
            />
          )}
        </div>

        {entity.option && !entity.option.isNew && (
          <form onSubmit={onSubmit}>
            <section>
              <div className="col-12">
                <h2>Edital</h2>
              </div>

              <div className="row">
                <div className="col-12 col-md-4 mb-3">
                  <label
                    htmlFor="processoAdministrativo"
                    className="form-label"
                  >
                    Processo administrativo
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="processoAdministrativo"
                    name="processoAdministrativo"
                  />
                </div>

                <div className="col-6 col-md-4 mb-3">
                  <label htmlFor="modalidade" className="form-label">
                    Modalidade
                  </label>
                  <Select
                    inputId="modalidade"
                    name="modalidade"
                    options={[
                      { value: "CONCORRENCIA", label: "Concorrência" },
                      { value: "TOMADA_DE_PRECO", label: "Tomada de Preço" },
                      { value: "CONVITE", label: "Convite" },
                      { value: "RDC", label: "RDC - Registro Diferenciado de Contratação" },
                      { value: "PREGAO_PRESENCIAL", label: "Pregão Presencial" },
                      { value: "PREGAO_ELETRONICO", label: "Pregão Eletronico" },
                      { value: "OUTRO", label: "Outro" },
                    ]}
                    isSearchable={false}
                    placeholder="Selecione..."
                  />
                </div>

                <div className="col-6 col-md-4 mb-3">
                  <label htmlFor="numeroDaModalidade" className="form-label">
                    N° da Modalidade
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="numeroDaModalidade"
                    name="numeroDaModalidade"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6 mb-3">
                  <label htmlFor="status" className="form-label">
                    Status
                  </label>
                  <Select
                    inputId="status"
                    name="status"
                    options={[
                      { value: "Aberto", label: "Aberto" },
                      { value: "Fechado", label: "Fechado" },
                      { value: "Inpugnado", label: "Inpugnado" },
                      { value: "Cancelado", label: "Cancelado" },
                      { value: "Suspenso", label: "Suspenso" },
                      { value: "Outro", label: "Outro" },
                    ]}
                    isSearchable={false}
                    placeholder="Selecione..."
                    defaultValue={{ value: "Aberto", label: "Aberto" }}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                <label htmlFor="editalUrl" className="form-label">
                      Edital URL
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="editalUrl"
                      name="editalUrl"
                    />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                <label htmlFor="comentario" className="form-label">
                      Comentário
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="comentario"
                      name="comentario"
                    />
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-md-9 mb-3">
                  <label htmlFor="cidade" className="form-label">
                    Cidade
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cidade"
                    name="cidade"
                    required
                  />
                </div>
                <div className="col-12 col-md-3 mb-3">
                  <label htmlFor="cep" className="form-label">
                    CEP
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="cep"
                    name="cep"
                    required
                    value={cep}
                    onChange={(e) => setCep(e.target.value)}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-12 mb-3">
                  <label htmlFor="titulo" className="form-label">
                    Objeto
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    id="titulo"
                    name="titulo"
                    required
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-6 mb-3">
                  <label htmlFor="valor" className="form-label">
                    Valor (opcional)
                  </label>
                  <input
                    type="number"
                    min="1"
                    className="form-control"
                    id="valor"
                    name="valor"
                    step="0.01"
                    onChange={onValorChange}
                  />
                </div>
                <div className="col-6 mb-3">
                  <label htmlFor="dataDeEncerramento" className="form-label">
                    Data de encerramento
                  </label>
                  <input
                    type="datetime-local"
                    className="form-control"
                    id="dataDeEncerramento"
                    name="dataDeEncerramento"
                    required
                  />
                </div>
              </div>
            </section>

            <div className="mt-5">
              {edict.error &&
                <div className="bg-red-300 flex justify-center items-center py-2 rounded text-lg text-red-600 font-medium">
                  {edict.error}
                </div>
              }
              <button type="submit" className="btn btn-primary btn-lg w-100">
                Criar
              </button>
            </div>
          </form>
        )}
      </div>
    </div>
  );
};

export default CreateEdictPage;
