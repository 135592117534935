
/**
 * used to format numbers into Brazilian currency
 * 
 * use: 
 * 
 * money.format(amount);
 * 
 */
export const money = new Intl.NumberFormat("de-AT", {
    style: "currency",
    currency: "BRL",
    currencyDisplay: "symbol"
});