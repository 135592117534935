import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchEdicts } from "../store/edict/actions";
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import Loading from '../components/Loading'
import Error from '../components/Error'
import {money} from '../library/money';

dayjs.extend(utc)


const HomePage = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { loading, error, data } = useSelector((state) => state.edict.edicts);

  useEffect(() => {
    dispatch(fetchEdicts());
  }, [dispatch]);

  useEffect(() => {
    const appbar = document.getElementById("appbar")
    const tableHeader = document.getElementById("table-header")
    if(appbar && tableHeader){
      tableHeader.style.top = `${appbar.offsetHeight}px`
    }

    window.addEventListener("resize", () => {
      const appbar = document.getElementById("appbar")
      const tableHeader = document.getElementById("table-header")
      if(appbar && tableHeader){
        tableHeader.style.top = `${appbar.offsetHeight}px`
      }
    })
  })

  if (loading) {
    return  <Loading/>;
  }

  if (error || !data) {
    return <Error>Error</Error>;
  }

  console.log('HELLOWW!');
  console.log(data);

  return (
    <div style={{ maxWidth: "100vw" }}>
      <div className="w-full">
        <table
          className="w-full text-left border-none"
          cellSpacing="0"
          cellPadding="0"
        >
          <thead className="font-semibold sticky bg-white" id="table-header">
            <tr>
              <th>
                <div className="font-semibold border-b border-black p-2 pl-12">
                  Entidade
                </div>
              </th>
              <th>
                <div className="font-semibold border-b border-black p-2 text-center">
                  Modalidade
                </div>
              </th>
              <th>
                <div className="font-semibold border-b border-black p-2 text-center">
                  N°
                </div>
              </th>
              <th>
                <div className="font-semibold border-b border-black p-2 pl-5">
                  Objeto
                </div>
              </th>
              <th>
                <div className="font-semibold border-b border-black p-2 text-center">
                  Encerramento
                </div>
              </th>
              <th>
                <div className="font-semibold border-b border-black p-2 text-center">
                  Valor
                </div>
              </th>
              <th>
                <div className="font-semibold border-b border-black p-2 pr-12 text-center">
                  Situação
                </div>
              </th>
            </tr>
          </thead>
          <tbody className="text-sm">
            {data.map((edict, index) => (
              <tr
                key={edict._id}
                className={`cursor-pointer ${index % 2 ? "bg-white" : "bg-gray-100"}`}
                onClick={() => history.push(`/edital?edictId=${edict._id}`)}
              >
                <td className="p-2 pl-12">{edict.nomeDaEntidade}</td>
                <td className="p-2 text-center font-semibold ">{edict.modalidade}</td>
                <td className="p-2 font-semibold text-center">
                  {edict.numeroDaModalidade}
                </td>
                <td className="p-2">{edict.titulo}</td>
                <td className="p-2 text-center">
                  {edict.dataDeEncerramento
                    ?
                        <p>
                          <span className="whitespace-nowrap">
                            {dayjs(edict.dataDeEncerramento).utc().format(
                              "DD-MM-YYYY"
                            )}
                          </span>
                          <br />
                          <span className="whitespace-nowrap">
                            {dayjs(edict.dataDeEncerramento).utc().format("HH:mm:ss")}
                          </span>
                        </p>
                    : "-"}
                </td>
                <td className="p-2 font-semibold text-center">
                  {edict.valor ? money.format(edict.valor) : "Sem Valor"}
                </td>
                <td className="p-2 pr-12 font-semibold text-center">{edict.status ?? "?"}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default HomePage;
