import {
  USER_LOGIN_START,
  USER_LOGIN_SUCCESS,
  USER_LOGIN_ERROR,
  USER_LOGOUT,
} from "./actions";

const initialState = {
  loading: false,
  error: null,
  data: localStorage.getItem("user"),
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_LOGIN_START: {
      return { ...state, loading: true, error: null };
    }
    case USER_LOGIN_SUCCESS: {
      localStorage.setItem("user", JSON.stringify(action.login.user));
      localStorage.setItem("token", action.login.token);
      return { ...state, loading: false, data: { ...action.login.user } };
    }
    case USER_LOGIN_ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    case USER_LOGOUT: {
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      return { ...state, data: null };
    }

    default:
      return state;
  }
}
