import React from "react";
import { Route, Switch } from "react-router-dom";
import { useSelector } from "react-redux";
import LoginPage from "./pages/Login";

import CreateEdictPage from "./pages/CreateEdict";
import EdictPage from "./pages/Edict";
import EditEdictPage from "./pages/EditEdict";
import HomePage from "./pages/Home";
import Appbar from "./components/Appbar";
import { Appbar as BaseAppbar } from "ui-kit";

const Routes = () => {
  const user = useSelector((state) => state.user);

  if (!user.data) {
    return (
      <div className="mt-16 h-full">
        <BaseAppbar logo="/img/logo.png" />
        <LoginPage />
      </div>
    );
  }

  return (
    <div>
      <Appbar />
      <Switch>
        <Route exact path="/">
          <HomePage />
        </Route>
        <Route exact path="/criar-edital">
          <CreateEdictPage />
        </Route>
        <Route exact path="/edital">
          <EdictPage />
        </Route>
        <Route exact path="/editar-edital/:id">
          <EditEdictPage />
        </Route>
      </Switch>
    </div>
  );
};

export default Routes;
