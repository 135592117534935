import { MdError } from 'react-icons/md'

export default function Error({children}) {
    return  <div
                className="flex items-center justify-center h-screen absolute
                            top-0 left-0 w-full text-4xl font-bold text-red-600"
            >
                <MdError/>
                <span className="ml-2">{children}</span>
            </div>
}