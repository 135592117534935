import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import { useDispatch, useSelector } from "react-redux";
import { deleteFile, edictUpload, fetchEdict } from "../store/edict/actions";
import { AiOutlineFilePdf } from 'react-icons/ai'
import dayjs from "dayjs";
import utc from 'dayjs/plugin/utc'
import MyDropzone from "../components/DropZone";
import Loading from '../components/Loading'
import Error from '../components/Error'
import api from '../api/carta-convite'
import {money} from '../library/money';
import {getModalityLabel} from '../library/util';

dayjs.extend(utc);

const EdictPage = () => {
  const [files, setFiles] = useState([]);
  const { edictId } = queryString.parse(useLocation().search)
  const dispatch = useDispatch();
  const {
    current: { loading, error, data },
    updating,
  } = useSelector((state) => state.edict);

  useEffect(() => {
    dispatch(fetchEdict(edictId));
  }, [edictId, dispatch]);

  if (loading) {
    return  <Loading/>;
  }

  if (error || !data) {
    return <Error>Ocorreu um erro</Error>;
  }

  const update = () => {
    const form = new FormData();
    form.append("arquivos", files[0]);
    dispatch(edictUpload(form, data._id));
  };

  const removeFile = (fileId) => {
    dispatch(deleteFile(data, fileId))
  }

  const downloadFile = async (path, fileName) => {
    const fileKey = path.split(/\/(.+)/)[1]
    const blob = await api.getFile(data._id, fileKey)
    const url = URL.createObjectURL(blob)
    const a = document.createElement('a');
    a.style.display = 'none';
    a.href = url;

    a.download = fileName
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  return (
    <div className="bootstrap mt-4">
      <div className="container pb-5" style={{ maxWidth: "800px" }}>
        <div>
          <h2 className="h3">Entidade</h2>

          <h5 className="text-gray-600">{data.nomeDaEntidade}</h5>
          <h5 className="text-gray-600">Cidade: {data.entity?.cidade}</h5>
          <h5 className="text-gray-600">CEP: {data.entity?.cep}</h5>
        </div>

        <div>
          <h2 className="h3">Edital</h2>

          <h5 className="text-gray-600">Objeto: {data.titulo}</h5>
          <h5 className="text-gray-600">Data do Encerramento: {data.dataDeEncerramento ?
              <>
                <span className="whitespace-nowrap">
                  {
                    dayjs(data.dataDeEncerramento).utc().format("DD-MM-YYYY")
                  }  {dayjs(data.dataDeEncerramento).utc().format("HH:mm:ss")}
                </span>
              </>
            :
              "-"}
          </h5>
          <h5 className="text-gray-600">Status: {data.status}</h5>
          <h5 className="text-gray-600">Processo administrativo: {data.processoAdministrativo}</h5>
          <h5 className="text-gray-600">Modalidade: {getModalityLabel(data.modalidade)}</h5>
          <h5 className="text-gray-600">N° da Modalidade: {data.numeroDaModalidade}</h5>
          <h5 className="text-gray-600">Valor: {data.valor ? money.format(data.valor) : ""}</h5>
          <h5 className="text-gray-600">Comentário: {data.comentario}</h5>
          <h5 className="text-gray-600">
            Link: <a href={data.editalUrl}  target="_blank" rel="noopener noreferrer">{data.editalUrl}</a>
          </h5>
        </div>

        <div>
          <h2 className="h3">Documentos</h2>

          {data.docs && (
            <p>
              {data.docs.map((doc) => (
                <span key={doc.name} className="badge bg-primary">
                  {doc.name}
                </span>
              ))}
            </p>
          )}

          <MyDropzone onChange={(file) => setFiles(file)} />

          <button
            disabled={updating.loading}
            className="mt-4 btn btn-primary"
            onClick={update}
          >
            Upload
          </button>

          <div>
            {data.files[0] &&
            <>
              <div className="flex justify-between text-xl font-semibold text-gray-600 mt-2 mb-3">
                <span>Arquivos Selecionados:</span>
                <span>Excluir Arquivos</span>
              </div>
              <div>
                {data.files.map((file, index) => (
                  <div key={file.fileId} className="flex justify-between mb-2">
                    <span className="font-medium flex items-center gap-1 text-lg">
                      <AiOutlineFilePdf color="red"/>
                      <span
                        className="text-blue-500 cursor-pointer hover:text-blue-600 "
                        onClick={() => downloadFile(file.s3Path, file.fileName)}
                      >
                        {file.fileName}
                      </span>
                       - {(file.size / (1024 * 1024)).toFixed(2)}MB
                    </span>
                    <button
                      className="bg-red-500 rounded text-white py-1 px-7 font-semibold"
                      onClick={() => removeFile(file.fileId)}
                    >
                      Delete
                    </button>
                  </div>
                ))}
              </div>
            </>
            }
          </div>
        </div>
      </div>
    </div>
  );
};

export default EdictPage;
