export const FETCH_EDICT_START = "FETCH_EDICT_START";
export const fetchEdictStart = () => ({
  type: FETCH_EDICT_START,
});

export const FETCH_EDICT_SUCCESS = "FETCH_EDICT_SUCCESS";
export const fetchEdictSuccess = (edict) => ({
  type: FETCH_EDICT_SUCCESS,
  edict,
});

export const FETCH_EDICT_ERROR = "FETCH_EDICT_ERROR";
export const fetchEdictError = (error) => ({
  type: FETCH_EDICT_ERROR,
  error,
});

export const SET_EDICT_ENTITY = "SET_EDICT_ENTITY";
export const setEdictEntity = (entity) => ({
  type: SET_EDICT_ENTITY,
  entity,
});

export const fetchEdict = (id) => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(fetchEdictStart());

    try {
      const edict = await cartaConvite.getEdict(id);

      dispatch(fetchEdictSuccess(edict));

      const entity = await cartaConvite.getEntity(edict.entidadeId);
      dispatch(setEdictEntity(entity));
    } catch (error) {
      dispatch(fetchEdictError(error.message));
    }
  };
};

export const FETCH_EDICTS_START = "FETCH_EDICTS_START";
export const fetchEdictsStart = () => ({
  type: FETCH_EDICTS_START,
});

export const FETCH_EDICTS_SUCCESS = "FETCH_EDICTS_SUCCESS";
export const fetchEdictsSuccess = (edicts) => ({
  type: FETCH_EDICTS_SUCCESS,
  edicts,
});

export const FETCH_EDICTS_ERROR = "FETCH_EDICTS_ERROR";
export const fetchEdictsError = (error) => ({
  type: FETCH_EDICTS_ERROR,
  error,
});

export const fetchEdicts = () => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(fetchEdictsStart());

    try {
      const edicts = await cartaConvite.getEdicts();
      dispatch(fetchEdictsSuccess(edicts));
    } catch (error) {
      dispatch(fetchEdictsError(error));
    }
  };
};

export const EDICT_UPLOAD_START = "EDICT_UPLOAD_START";
export const edictUploadStart = (id) => ({
  type: EDICT_UPLOAD_START,
  id,
});

export const EDICT_UPLOAD_SUCCESS = "EDICT_UPLOAD_SUCCESS";
export const edictUploadSuccess = (data) => ({
  type: EDICT_UPLOAD_SUCCESS,
  data,
});

export const EDICT_UPLOAD_ERROR = "EDICT_UPLOAD_ERROR";
export const edictUploadError = (error) => ({
  type: EDICT_UPLOAD_ERROR,
  error,
});

export const edictUpload = (files, id) => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(edictUploadStart());

    try {
      const newEdict = await cartaConvite.uploadEdictFiles(files, id);
      dispatch(edictUploadSuccess(newEdict));
    } catch (error) {
      dispatch(edictUploadError(error));
    }
  };
};


export const UPDATE_EDICT_START = "UPDATE_EDICT_START";
export const updateEdictStart = (id) => ({
  type: UPDATE_EDICT_START,
  id,
});

export const UPDATE_EDICT_SUCCESS = "UPDATE_EDICT_SUCCESS";
export const updateEdictSuccess = (data) => ({
  type: UPDATE_EDICT_SUCCESS,
  data,
});

export const UPDATE_EDICT_ERROR = "UPDATE_EDICT_ERROR";
export const updateEdictError = (error) => ({
  type: UPDATE_EDICT_ERROR,
  error,
});

export const updateEdict = (edict) => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(updateEdictStart(edict._id));

    try {
      const newEdict = await cartaConvite.updateEdict(edict, edict._id);
      dispatch(updateEdictSuccess(newEdict));
    } catch (error) {
      dispatch(updateEdictError(error));
    }
  };
};

export const UPDATE_EDICT_RESET = "UPDATE_EDICT_RESET";
export const updateEdictReset= () => ({
  type: UPDATE_EDICT_RESET
});

export const deleteFile = (edict, fileId) => {
  return async (dispatch, _, { cartaConvite }) => {
    dispatch(updateEdictStart(edict._id));

    try {
      await cartaConvite.deleteFile(edict._id, fileId);
      const newEdict = {
        ...edict,
        files: edict.files.filter(file => file.fileId !== fileId && file)
      }

      dispatch(updateEdictSuccess(newEdict));
    } catch (error) {
      dispatch(updateEdictError(error));
    }
  };
};